import React from 'react';
import PropTypes from 'prop-types';
import {
    Nav,
    ListLinkItem
} from './../';

const propTypes = {
    navItems: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    vertical: PropTypes.bool,
    id: PropTypes.string
};

function Pills(props) {
    const navClasses = [
        'nav-pills',
        'nav-fill',
        'bg-dark',
        `${(props.vertical) ? 'flex-column' : ''}`,
    ];
    
    return (
        <Nav classes={navClasses}
            id={props.id}>
            {props.navItems.map((item, key) => {
                return (
                    <ListLinkItem
                        classes={['navItem']}
                        linkClasses={['navLink']}
                        linkTo={item.linkTo}
                        linkType="RouterNavLink"
                        text={item.text}
                        key={key} />
                );
            })}
        </Nav>
    );
}

Pills.propTypes = propTypes;

export default Pills;
