import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './design/scss/_app.scss';
import NavBar from './components/content/header/NavBar';
import Footer from './components/content/footer/Footer';
import {
    Home,
    Blog,
    BlogArticle,
    Contact,
    CV,
    Portfolio,
    PortfolioItem,
    Error404,
} from './pages';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLinkedin, faGithub, faInstagram, faTwitter }
    from '@fortawesome/free-brands-svg-icons';

library.add(
    faLinkedin,
    faGithub,
    faInstagram,
    faTwitter,
);

class App extends Component {
    render() {
        return (
            <Router>
                <div className="App">
                    <header className="App-header">
                        <NavBar />
                    </header>
                    <div id="main-content">
                        <Switch>
                            <Route path="/" exact component={Home} />
                            <Route path="/curriculum-vitae" component={CV} />
                            <Route path="/blog" exact component={Blog} />
                            <Route path="/blog/:articleTitle" exact component={BlogArticle} />
                            <Route path="/portfolio" exact component={Portfolio} />
                            <Route path="/portfolio/:portfolioItem" exact component={PortfolioItem} />
                            <Route path="/contact" exact component={Contact} />
                            <Route component={Error404} />
                        </Switch>
                    </div>
                    <Footer />
                </div>
            </Router>
        );
    }
}

export default App;
