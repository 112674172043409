import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    classes: PropTypes.arrayOf(PropTypes.string),
    linkTo: PropTypes.string,
};

function BrowserLink(props) {
    return (
        <a
            href={props.linkTo}
            className={props.classes.join(' ')}>
                {props.children}
        </a>
    );
}

BrowserLink.propTypes = propTypes;

export default BrowserLink;
