import React from 'react';
import { UnorderedList, ListItem } from './../../../base';
import Skills from './../../../../data/ProfessionalSkills';

function ProfessionalSkills(props) {
    return (
        <div>
            <h2>Professional Skills</h2>
            <UnorderedList classes={['list-unstyled', 'pl-3']}>
                {Skills.map((skill) => (
                    <ListItem
                        key={skill.id}
                        classes={['py-1']}>
                        {skill.name}
                    </ListItem>
                ))}
            </UnorderedList>
        </div>
    );
}

export default ProfessionalSkills;
