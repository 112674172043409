import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    classes: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
};

const defaultProps = {
    classes: [],
};

function Nav(props) {
    return (
        <ul className={
            `nav ${props.classes.join(' ')}`
            }
            id={props.id}>
            {props.children}
        </ul>
    )
}

Nav.propTypes = propTypes;
Nav.defaultProps = defaultProps;

export default Nav;
