import React from 'react';

function Definition(props) {
    return (
        <div className="card mt-3 bg-dark">
            <div className="card-header">
                <h4 className="card-title">Definition</h4>
            </div>
            <div className="card-body">
                <strong>Noun</strong>
                <ol>
                    <li>A person who enjoys product development from discovery to deployment
                    while focusing on inclusivity and user experience.</li>
                    <li>Has refined skills of time management, project management, and communication
                through a career of managing developers while building web applications.</li>
                    <li>A dedicated individual with a curiosity and passion for innovative solutions
                    who excels at delivering excellence while working cohesively with others.</li>
                    <li>A web generalist</li>
                </ol>
            </div>
        </div>
    );
}

export default Definition;
