import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
    progress: PropTypes.number,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    background: PropTypes.string,
    striped: PropTypes.bool,
    animated: PropTypes.bool,
};

const defaultProps = {
    progress: 0,
    minValue: 0,
    maxValue: 100,
    background: 'primary',
    striped: false,
    animated: false,
};

function ProgressBar(props) {
    return (
        <div className="progress">
            <div className={
                    classNames(
                        'progress-bar',
                        `bg-${props.background}`,
                        {'progress-bar-striped': props.striped},
                        {'progress-bar-animated': props.animated},
                        )}
                style={{'width': `${props.progress}%`}}
                role="progressbar"
                aria-valuenow={props.progress}
                aria-valuemin={props.minValue}
                aria-valuemax={props.maxValue} />
        </div>
    )
}

ProgressBar.propTypes = propTypes;
ProgressBar.defaultProps = defaultProps;

export default ProgressBar;
