const TechnicalSkills = [
    {
        "id": 1,
        "name": "JavaScript",
        "proficiency": 100
    },
    {
        "id": 2,
        "name": "NodeJS",
        "proficiency": 100
    },
    {
        "id": 3,
        "name": "React",
        "proficiency": 90
    },
    {
        "id": 4,
        "name": "Redux",
        "proficiency": 90
    },
    {
        "id": 5,
        "name": "Vue",
        "proficiency": 80
    },
    {
        "id": 6,
        "name": "Vuex",
        "proficiency": 80
    },
    {
        "id": 7,
        "name": "jQuery",
        "proficiency": 100
    },
    {
        "id": 8,
        "name": "SASS/SCSS",
        "proficiency": 90
    },
    {
        "id": 9,
        "name": "Bootstrap",
        "proficiency": 100
    },
    {
        "id": 10,
        "name": "CSS3",
        "proficiency": 90
    },
    {
        "id": 11,
        "name": "HTML5",
        "proficiency": 100
    },
    {
        "id": 12,
        "name": "WCAG",
        "proficiency": 90
    },
    {
        "id": 13,
        "name": "PHP",
        "proficiency": 90
    },
    {
        "id": 14,
        "name": "Laravel",
        "proficiency": 90
    },
    {
        "id": 15,
        "name": "CFML",
        "proficiency": 90
    },
    {
        "id": 16,
        "name": "MySQL",
        "proficiency": 85
    },
    {
        "id": 17,
        "name": "SQL",
        "proficiency": 80
    },
    {
        "id": 18,
        "name": "MongoDB",
        "proficiency": 50
    },
    {
        "id": 19,
        "name": "AWS",
        "proficiency": 40
    },
    {
        "id": 20,
        "name": "C#",
        "proficiency": 30
    },
    {
        "id": 21,
        "name": "Go",
        "proficiency": 25
    },
    {
        "id": 22,
        "name": "Ruby",
        "proficiency": 30
    },
    {
        "id": 23,
        "name": "Adobe Creative Suite",
        "proficiency": 100
    },
];

export default TechnicalSkills;
