import React from 'react';
import { UnorderedList, ListItem, ProgressBar } from './../../../base';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import Skills from './../../../../data/TechnicalSkills';

function TechnicalSkills(props) {
    return (
        <div>
            <h2>Technical Skills</h2>
            <em>Graph is representive of personal experience, familiarity, and confidence with the technology</em>
            <UnorderedList classes={['list-unstyled']}>
            {reverse(sortBy(Skills, 'proficiency')).map((skill, name) => (
                <ListItem
                        key={skill.id}
                        classes={['d-flex', 'py-1']}>
                        <div className="col-3 text-right">{skill.name}</div>
                        <div className="col pt-1"><ProgressBar progress={skill.proficiency} /></div>
                </ListItem>
            ))}
            </UnorderedList>
        </div>
    );
}

export default TechnicalSkills;
