import React from 'react';

function Education(props) {
    return (
        <div>
            <h2>Education</h2>
            <div className="row">
                <div className="col">
                    <h3><small>MOOCs and OSE online courses</small></h3>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <h3><small>Western Governor's University</small></h3>
                    <h4><small><em>BS Computer Science</em></small></h4>
                </div>
                <div className="col text-right">2020</div>
            </div>
            <div className="row">
                <div className="col">
                    <h3><small>Houston Community College</small></h3>
                    <h4><small><em>AA English</em> <small>Highest Honors</small></small></h4>
                </div>
                <div className="col text-right">2017-2018</div>
            </div>
            <div className="row">
                <div className="col">
                    <h3><small>Loan Star College</small></h3>
                </div>
                <div className="col text-right">2004-2007</div>
            </div>
            <div className="row">
                <div className="col">
                    <h3><small>Baylor University</small></h3>
                </div>
                <div className="col text-right">2003-2004</div>
            </div>
        </div>
    );
}

export default Education;
