import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    classes: PropTypes.arrayOf(PropTypes.string),
};

function UnorderedList(props) {
    return (
        <ul
            className={props.classes.join(' ')}>
            {props.children}
        </ul>
    );
}

UnorderedList.propTypes = propTypes;

export default UnorderedList;
