const ProfessionalSkills = [
    {
        "id": 1,
        "name": "Leader"
    },
    {
        "id": 2,
        "name": "Mentor"
    },
    {
        "id": 3,
        "name": "Teacher"
    },
    {
        "id": 4,
        "name": "Verbal and Visual Communication"
    },
    {
        "id": 5,
        "name": "Creative",
    },
    {
        "id": 6,
        "name": "Resourceful"
    },
    {
        "id": 7,
        "name": "Willingness to learn and take direction"
    },
    {
        "id": 8,
        "name": "Able to adapt quickly"
    }
];

export default ProfessionalSkills;
