import React from 'react';
import { UnorderedList, ListLinkItem } from './../../base';


function generateNavItems(navItems) {
    const list = navItems.map((item, index) => {
        return (
            <ListLinkItem
                linkTo={item.link}
                linkType="RouterNavLink"
                text={item.text}
                classes={['nav-item']}
                linkClasses={['nav-link']}
                key={index.toString()} />
        );
    });
    return list;
}

function NavItems(props) {
    const navItems = [
        {
            text: 'Home',
            link: '/',
        },
        {
            text: 'CV',
            link: '/curriculum-vitae'
        },
        {
            text: 'Portfolio',
            link: '/portfolio',
        },
        {
            text: 'Blog',
            link: '/blog',
        },
        {
            text: 'Contact',
            link: '/contact',
        },
    ];
    return (
        <UnorderedList
            classes={props.classes}>
            {generateNavItems(navItems)}
        </UnorderedList>
    );
}

export default NavItems;
