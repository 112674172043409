import React from 'react';
import Job from './Job';




function ProfessionalExperience(props) {
    const jobs = [
        {
            id: 0,
            titles: ['User Experience Architect'],
            company: 'Norton Rose Fulbright',
            startDate: 'February 2019',
            endDate: 'Current',
            summary: [{
                id: 0,
                text: `Branding, Design, and Development of Intranet and
                    Internet Sites utilizing CFML, JavaScript, jQuery, HTML5,
                    CSS3, SASS, C#, .NET, SQL, ActionScript, Manages direct and
                    indirect reports.`
            }],
        },
        {
            id: 1,
            titles: ['Head of UI/UX'],
            company: 'VRBex',
            startDate: 'April 2018',
            endDate: 'January 2019',
            summary: `Manager in Information Technology for front-end application 
                        development, user experience, and graphic design. Cloud services with AWS
                        Branding and product design with Adobe Creative Cloud, including documents with
                        Adobe InDesign, Graphics with Adobe Illustrator and Photoshop, and videos with
                        Adobe After Effects and Adobe Premier. Print product design includes VRBex
                        coin, tradeshow booths, wall clings and various marketing materials.
                        Application development with React, PHP, Laravel, Vue, Go, Prisma
                        and version control with Git.`
        },
        {
            id: 2,
            titles: ['Adjuct Instructor'],
            company: 'The Coding Bootcamp at The University of Texas at Austin',
            startDate: 'October 2016',
            endDate: 'Current',
            summary: [{
                id: 0,
                text: `Teaches 250+ in-class hours of curriculum including HTML, CSS, 
                    JavaScript, jQuery, Node, MySQL, Handlebars, MongoDB, and React.`
            },{
                id: 1,
                text: `6 month program in which students complete 3 web application projects.`,
            }, {
                id: 2,
                text: `Additional Technologies include C#, Ruby, Python, SASS`
            }]
        },
        {
            id: 3,
            titles: ['Fullstack Developer'],
            company: 'Freelance',
            startDate: '2002',
            endDate: 'Current',
            summary: `Provides services including discovery, design, development,
                        and deployment for a variety of customers in varied industries including
                        legal, music, real estate, food, and FinTech. Technologies based on client
                        requests and include JavaScript, jQuery, React, Vue, PHP, Laravel, C#.
                        Deployed to shared web hosting and cloud services, including AWS. Worked
                        with firmware for IoT devices. Designed branding guidelines for multiple
                        companies. Collaborates with others when necessary for completion of
                        projects and deadlines.`
        },
        {
            id: 4,
            titles: ['User Experience Architect', 'Sr. Web Designer', 'Web Designer'],
            company: 'Norton Rose Fulbright',
            startDate: 'June 2008',
            endDate: 'April 2018',
            summary: [{
                id: 0,
                text: `Branding, Design, and Development of Intranet and
                    Internet Sites utilizing CFML, JavaScript, jQuery, HTML5,
                    CSS3, SASS, C#, .NET, SQL, ActionScript, Manages direct and
                    indirect reports.`
            },{
                id: 1,
                text: `Over the course of three positions and a decade of experience,
                    Latisha worked on a variety of projects that included LMS for
                    CLE credit, Accounting Corrections and Data Migration to SAP,
                    rebranding and redesign, and a combination with other firms.`
            }]
        }
    ];

    return (
        <div>
            <h2>Professional Experience</h2>
            {jobs.map((job) => (<Job {...job} key={job.id}/>))}
        </div>
    );
}

export default ProfessionalExperience;
