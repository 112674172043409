import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const propTypes = {
    classes: PropTypes.arrayOf(PropTypes.string),
    linkTo: PropTypes.string,
};

const defaultProps = {
    classes: [],
};

function RouterLink(props) {
    return (
        <Link
            className={props.classes.join(' ')}
            to={props.linkTo}>
            {props.children}
        </Link>
    );
};

RouterLink.propTypes = propTypes;
RouterLink.defaultProps = defaultProps;
export default RouterLink;
