import React, { useState } from 'react';
import RouterLink from '../../base/links/RouterLink';
import NavItems from './../navItems/NavItems';
import Logo from './../../../design/images/coin-logo.svg';
import './../../../design/scss/_navbar.scss';
import pull from 'lodash/pull';
import concat from 'lodash/concat';
import indexOf from 'lodash/indexOf';

function handleNavToggle(toggleClasses) {
    if(indexOf(toggleClasses, 'show') === -1) {
        return concat(toggleClasses, 'show');
    }

    return pull(toggleClasses, 'show');
}

function NavBar(props) {
    const navBarClasses = [
        'navbar',
        'navbar-expand-lg',
        'navbar-dark',
        'bg-dark',
    ];
    const navBarToggleClasses = [
        'collapse',
        'navbar-collapse',
    ];

    const [toggleClasses, setNavBarToggle] = useState(navBarToggleClasses);

    return (
        <nav
            className={navBarClasses.join(' ')}>
                <RouterLink
                    linkTo="/"
                    classes={['navbar-brand']}>
                    <img
                        src={Logo}
                        alt="Latisha McNeel LM Tree Circle"
                        className="d-inline-block align-top"
                        style={{height: "50px"}}/>
                    <h1 className="d-inline-block ml-2">
                        Latisha<br />McNeel
                    </h1>
                </RouterLink>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={() => setNavBarToggle(handleNavToggle)}>
                        <span className="navbar-toggler-icon"></span>
                </button>
                <div className={toggleClasses.join(" ")} id="navbarSupportedContent">
                    <div className="ml-auto d-md-none d-lg-block">
                        <h2 className="text-monospace" id="me">
                            <span className="text-primary">const</span>
                            <span className="text-danger"> life</span>
                            <span className="text-primary"> = ['</span>
                            <span className="text-success">family</span>
                            <span className="text-primary">', '</span>
                            <span className="text-success">developer</span>
                            <span className="text-primary">', '</span>
                            <span className="text-success">designer</span>
                            <span className="text-primary">', '</span>
                            <span className="text-success">writer</span>
                            <span className="text-primary">'];</span>
                        </h2>
                    </div>
                    <NavItems classes={['navbar-nav ml-auto']}/>
                </div>
        </nav>
    );
}

export default NavBar;
