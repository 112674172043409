import React, { useRef } from 'react';
import { Route } from 'react-router-dom';
import {
    Row,
    ToTopLink,
} from './../components/base/'
import SideNav from './../components/content/cv/SideNav';
import {
    AwardsAndOrganizations,
    Education,
    Languages,
    ProfessionalExperience,
    ProfessionalSkills,
    ProfessionalStatement,
    Speeches,
    Summary,
    TechnicalSkills,
} from './../components/content/cv/sections';
import Tish from './../design/images/tish-transparent.png';
import './../design/scss/_cv.scss';
import camelCase from 'lodash/camelCase';

function scrollToSection(ref) {
    window.scrollTo({
        top: ref.current.offsetTop,
        behavior: "smooth",
    });
}

function CV(props) {
    const cvRefs = {
        professionalStatement: useRef('professional-statement'),
        summary: useRef('summary'),
        professionalExperience: useRef('professional-experience'),
        technicalSkills: useRef('technical-skills'),
        professionalSkills: useRef('professional-skills'),
        education: useRef('education'),
        speeches: useRef('speeches'),
        languages: useRef('languages'),
        awardsAndOrganizations: useRef('awards-and-organizations'),
        curriculumVitae: useRef('curriculum-vitae')
    };

    return (
        <div className="container" id="cv">
            <Row>
                <div className="col-md-9" ref={cvRefs.curriculumVitae}>
                    <Row>
                        <Route path="/curriculum-vitae/:section" children={(match) => {
                            if(match.match) scrollToSection(cvRefs[camelCase(match.match.params.section.trim())]);
                            else scrollToSection(cvRefs['curriculumVitae']);
                            return (
                                <div>
                                    <section ref={cvRefs.professionalStatement}>
                                        <ProfessionalStatement />
                                        <ToTopLink linkTo='/curriculum-vitae' />
                                    </section>
                                    <section ref={cvRefs.summary}>
                                        <Summary />
                                        <ToTopLink linkTo='/curriculum-vitae' />
                                    </section>
                                    <section ref={cvRefs.professionalExperience}>
                                        <ProfessionalExperience />
                                        <ToTopLink linkTo='/curriculum-vitae' />
                                    </section>
                                    <section ref={cvRefs.technicalSkills}>
                                        <TechnicalSkills />
                                        <ToTopLink linkTo='/curriculum-vitae' />
                                    </section>
                                    <section ref={cvRefs.professionalSkills}>
                                        <ProfessionalSkills />
                                        <ToTopLink linkTo='/curriculum-vitae' />
                                    </section>
                                    <section ref={cvRefs.education}>
                                        <Education  />
                                        <ToTopLink linkTo='/curriculum-vitae' />
                                    </section>
                                    <section ref={cvRefs.languages}>
                                        <Languages />
                                        <ToTopLink linkTo='/curriculum-vitae' />
                                    </section>
                                    <section ref={cvRefs.speeches}>
                                        <Speeches />
                                        <ToTopLink linkTo='/curriculum-vitae' />
                                    </section>
                                    <section ref={cvRefs.awardsAndOrganizations}>
                                        <AwardsAndOrganizations />
                                        <ToTopLink linkTo='/curriculum-vitae' />
                                    </section>
                                </div>
                        )}} />
                    </Row>
                </div>
                <div className="col-md-3 order-md-first border-right pt-3" id="cv-side-nav">
                    <div id="cv-side-nav-content">
                        <img
                            src={Tish}
                            className="img-thumbnail"
                            alt="Graphic Illustration of Tish"
                            style={{height: "150px"}}/>
                        <SideNav />
                    </div>
                </div>
            </Row>
        </div>
    );
}

export default CV;
