import React, { useState } from 'react';
import { TishTransparent } from './../design/images/';
import './../design/scss/_home.scss';
import {
    Definition,
    Origin,
    Examples,
} from './../components/content/home';
import {
    RouterNavLink,
    Row,
} from './../components/base';

function Home(props) {
    const [content, setContent] = useState('definition');
    const contentMap = {
        definition: <Definition />,
        examples: <Examples />,
        origin: <Origin />,
    };
    return (
        <div id="landing">
            <div className="background-image">
                <img src={TishTransparent} alt="Sketch of Latisha McNeel" />
            </div>
            <div className="container pt-3">
                <Row>
                    <div className="col-3">
                        <h3>Latisha McNeel</h3>
                        <p>[lə-TISH-ə] [m-uh-k-n-EE-l]</p>
                    </div>
                    <div className="col-1 text-center">
                        <RouterNavLink
                            classes={['btn', 'btn-light', 'full-width']}
                            linkTo="/curriculum-vitae">
                            CV
                        </RouterNavLink>
                    </div>
                    <div className="col-1 text-center">
                        <RouterNavLink
                            classes={['btn', 'btn-light']}
                            linkTo="/contact">
                            Contact
                        </RouterNavLink>
                    </div>
                </Row>
                <Row>
                    <div className="col-2 text-center">
                        <button
                            className={`
                                btn
                                btn-primary
                                ${(content === 'definition') ? 'active' : ''}
                            `}
                            onClick={() => setContent('definition')}>
                            (definition)
                        </button>
                    </div>
                    <div className="col-2 text-center">
                        <button
                            className={`
                                btn
                                btn-primary
                                ${(content === 'examples') ? 'active' : ''}
                            `}
                            onClick={() => setContent('examples')}>
                            (examples)
                        </button>
                    </div>
                    <div className="col-2 text-center">
                        <button
                            className={`
                                btn
                                btn-primary
                                ${(content === 'origin') ? 'active' : ''}
                            `}
                            onClick={() => setContent('origin')}>
                            (origin)
                        </button>
                    </div>
                </Row>
                <Row>
                    <div className="col-6">
                        {contentMap[content]}
                    </div>
                </Row>
            </div>
        </div>
    );
}

export default Home;
