import React from 'react';
import { UnorderedList, ListItem } from './../../../base';

function Languages(props) {
    return (
        <div>
            <h2>Languages</h2>
            <UnorderedList classes={['list-unstyled']}>
                <ListItem classes={['py-1', 'pl-3']}>English <em>Fluent</em></ListItem>
                <ListItem classes={['py-1', 'pl-3']}>Latin <em>Some Proficiency</em></ListItem>
                <ListItem classes={['py-1', 'pl-3']}>Spanish <em>Limited Proficiency</em></ListItem>
            </UnorderedList>
        </div>
    );
}

export default Languages;
