import React from 'react';
import { UnorderedList, ListItem } from './../../../base';

function AwardsAndOrganizations(props) {
    return (
        <div>
            <h2>Awards and Organizations</h2>
            <UnorderedList classes={['list-unstyled', 'pl-3']}>
                <ListItem classes={['py-1']}>
                    Phi Theta Kappa
                </ListItem>
                <ListItem classes={['py-1']}>
                    DreamHack Hackathon <em>1<sup>st</sup> place</em>
                </ListItem>
            </UnorderedList>
        </div>
    );
}

export default AwardsAndOrganizations;
