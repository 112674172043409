import React from 'react';
import {
    Pills
} from './../../base';
import './../../../design/scss/_sideNav.scss';

function SideNav(props) {
    const navItems = [
        {
            linkTo: '/curriculum-vitae/professional-statement',
            text: 'Professional Statement',
        },
        {
            linkTo: '/curriculum-vitae/summary',
            text: 'Summary',
        },
        {
            linkTo: '/curriculum-vitae/professional-experience',
            text: 'Professional Experience',
        },
        {
            linkTo: '/curriculum-vitae/technical-skills',
            text: 'Technical Skills',
        },
        {
            linkTo: '/curriculum-vitae/professional-skills',
            text: 'Professional Skills',
        },
        {
            linkTo: '/curriculum-vitae/education',
            text: 'Education',
        },
        {
            linkTo: '/curriculum-vitae/languages',
            text: 'Languages',
        },
        {
            linkTo: '/curriculum-vitae/speeches',
            text: 'Speeches',
        },
        {
            linkTo: '/curriculum-vitae/awards-and-organizations',
            text: 'Awards and Organizations',
        },
    ];
    return (
        <nav id="sideNav">
            <Pills
                navItems={navItems} 
                vertical={true} />
        </nav>
    );
}

export default SideNav;
