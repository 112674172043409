import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const propTypes = {
    classes: PropTypes.arrayOf(PropTypes.string),
    linkTo: PropTypes.string,
};

function RouterNavLink(props) {
    return (
        <NavLink
            to={props.linkTo}
            className={props.classes.join(" ")}>
                {props.children}
        </NavLink>
    );
}

RouterNavLink.propTypes = propTypes;

export default RouterNavLink;
