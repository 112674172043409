import React from 'react';

function Summary(props) {
    return (
        <div>
            <h2>Summary</h2>
            <p>Latisha McNeel designed and developed her first website in
                1997 as a hobby. In 2002, the hobby turned into a
                career with her first contract job redesigning a site in
                the real estate industry. In 2001-2002, Latisha McNeel
                competed in several hackathons, including Compaq Code Wars.</p>
            <p>As a freelancer, Latisha designed and developed websites
                for lawyers, real estate agents, music artists
                (John Mellencamp, Jimmy Buffet, Hall and Oates),
                recording/publishing studios, and IoT food devices.</p>
            <p>Latisha McNeel worked full time at Norton Rose Fulbright
                (previously Fulbright & Jaworski) from 2008-2018.
                During that decade, Latisha improved programming skills,
                learned project management and corporate branding techniques,
                and critical communication skills for progressing projects with
                stakeholders.</p>
            <p>In 2017, Latisha McNeel attended DreamHack
                in Austin where her team won first place in the Hackathon.
                Latisha focuses on being able to design, develop, and deploy
                applications with modern and relevant technologies while building
                cohesive, productive teams. While being a generalist, Latisha has
                learned usability techniques to apply to every corner of the industry.</p>
        </div>
    );
}

export default Summary;
