import React from 'react';
import PropTypes from 'prop-types';
import { Row } from './../../../base';

const propTypes = {
    titles: PropTypes.arrayOf(PropTypes.string),
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    company: PropTypes.string,
    summary: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.object)]),
}

function Job(props) {
    const {
        titles,
        startDate,
        endDate,
        company,
        summary,
    } = props;

    return (
        <div className="mt-3">
            <Row classes={['justify-content-between']}>
                <div className="col">
                    {titles.map((title) => (
                        <h3 key={title}>{title}</h3>
                    ))}
                </div>
                <div className="col text-right">
                    <h4>{company}</h4>
                    <span className="d-block">{startDate} - {endDate}</span>
                </div>
            </Row>
            <Row>
                <div className="col p-3">
                    {(typeof summary === 'string') ? (
                        <p>{summary}</p>
                    ) : (
                        summary.map((paragraph) => (
                            <p key={paragraph.id}>
                                {paragraph.text}
                            </p>
                        ))
                    )}
                </div>
            </Row>
        </div>
    );
}

Job.propTypes = propTypes;

export default Job;
