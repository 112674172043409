import React from 'react';

function ProfessionalStatement(props) {
    return (
        <div>
            <h2>Professional Statement</h2>
            <p>With a focus on inclusivity, Latisha McNeel is a
                senior full stack developer who enjoys product
                development from discovery to deployment.
                Refining time management, project management,
                and communication skills through a career of
                managing developers while building web applications,
                Latisha has strived to be a generalist in the industry.
                Latisha is a dedicated individual with a curiosity and
                passion for innovative solutions who excels at delivering
                excellence while working cohesively with others.</p>
        </div>
    );
}

export default ProfessionalStatement;
