import React from 'react';

function Speeches(props) {
    return (
        <div>
            <h2>Speeches</h2>
            <div className="row">
                <div className="col">
                    <h3><small>Telling a Story With Your Code</small></h3>
                    <h4><em><small>Primary Speaker</small></em></h4>
                </div>
                <div className="col text-right">
                    <a href="https://powertofly.com/career/live-chats/91081?utm_source=social&utm_medium=cpa&utm_campaign=ChatAndLearn&utm_content=G2i">2019 PowerToFly with G2i</a>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <h3><small>Understanding Intersectionality in the Gaming and Tech Industries</small></h3>
                    <h4><em><small>Panelist</small></em></h4>
                </div>
                <div className="col text-right">2019 Pax South</div>
            </div>
            <div className="row">
                <div className="col">
                    <h3><small>Women in Tech</small></h3>
                    <h4><em><small>Primary Speaker</small></em></h4>
                </div>
                <div className="col text-right">2018 Online Zoom Session</div>
            </div>
            <div className="row">
                <div className="col">
                    <h3><small>Sassy Bootstrap</small></h3>
                    <h4><em><small>Primary Speaker</small></em></h4>
                </div>
                <div className="col text-right">2017 Online Zoom Session</div>
            </div>
        </div>
    );
}

export default Speeches;
