import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    classes: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
    text: PropTypes.string,
};

const defaultProps = {
    classes: [],
};

function ListItem(props) {
    return (
        <li
            className={props.classes.join(' ')}
            id={props.id}>
                {props.text || props.children}
        </li>
    );
};

ListItem.propTypes = propTypes;
ListItem.defaultProps = defaultProps;

export default ListItem;
