import React from 'react';
import PropTypes from 'prop-types';
import concat from 'lodash/concat';

const propTypes = {
    screen: PropTypes.oneOfType(
        [
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),
    size:  PropTypes.oneOfType(
        [
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),
    classes: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
    classes: [],
};

const buildClasses = function(colDetails) {
    const classes = [];
    if(!colDetails.screen && !colDetails.size) concat(classes, ['col']);


    if(colDetails.classes) return concat(classes, colDetails.classes);

    return classes;
};

function Col(props) {
    const classes = buildClasses(props);

    return (
        <div
            className={classes.join()}>
            {props.children}
        </div>
    );
}

Col.propTypes = propTypes;
Col.defaultProps = defaultProps;

export default Col;
