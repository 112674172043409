import React from 'react';
import VRBexLogo from '../design/images/vrbex-logo-dark-background.svg';
import GlobalCV from '../design/images/GlobalCV-Search-Clean.jpg';
import LendingCove from '../design/images/lending-cove-homepage.jpg';

function Portfolio(props) {
    return (
        <div className="container" id="portfolio">
            <h2 className="border-bottom my-3">Portfolio</h2>
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="card bg-dark">
                        <img
                            src={GlobalCV}
                            className="card-top img-fluid"
                            alt="Screenshot of the Global CV Application" />
                        <div className="card-header">
                            <h3 className="card-title">
                                Norton Rose Fulbright
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="card bg-dark">
                        <img
                            src={VRBexLogo}
                            className="card-top img-fluid"
                            alt="VRBex Logo: Coin with Text" />
                        <div className="card-header">
                            <h3 className="card-title">
                                VRBex
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="card bg-dark">
                        <img
                            src={LendingCove}
                            className="card-top img-fluid"
                            alt="Screenshot of the Lending Cove Homepage in a mocked browser" />
                        <div className="card-header">
                            <h3 className="card-title">
                                Lending Cove/Vabzu
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Portfolio;
