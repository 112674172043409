import React from 'react';
import PropTypes from 'prop-types';
import BrowserLink from '../links/BrowserLink';
import RouterLink from '../links/RouterLink';
import RouterNavLink from '../links/RouterNavLink';

const propTypes = {
    classes: PropTypes.arrayOf(PropTypes.string),
    linkClasses: PropTypes.arrayOf(PropTypes.string),
    linkTo: PropTypes.string,
    linkType: PropTypes.string,
    text: PropTypes.string,
};

const defaultProps = {
    classes: [],
    linkClasses: [],
};

function getBrowserLinkOrRouterLink(link) {
    const {
        linkClasses,
        linkTo,
        linkType,
        text,
        children
    } = link;

    if(linkType === 'RouterLink') {
        return (
            <RouterLink
                classes={linkClasses}
                linkTo={linkTo}>
                {text || children}
            </RouterLink>
        );
    }else if(linkType === 'RouterNavLink'){
        return (
            <RouterNavLink
                classes={linkClasses}
                linkTo={linkTo}>
                {text || children}
            </RouterNavLink>
        );
    }

    return (
        <BrowserLink
            classes={linkClasses}
            linkTo={linkTo}>
            {text || children}
        </BrowserLink>
    );

}

function ListLinkItem(props) {
    return (
        <li
            className={props.classes.join()}>
            {getBrowserLinkOrRouterLink(props)}
        </li>
    );
}

ListLinkItem.propTypes = propTypes;
ListLinkItem.defaultProps = defaultProps;

export default ListLinkItem;
