import React from 'react';
import RouterLink from './RouterLink';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const propTypes = {
    linkTo: PropTypes.string,
};

function ToTopLink(props) {
    return (
        <div className="text-right pr-3">
            <RouterLink
                linkTo={props.linkTo}>
                <FontAwesomeIcon
                    icon={['fal', 'angle-double-up']} />
                <span className="d-inline-block pl-3">Go to Top</span>
            </RouterLink>
        </div>
    )
}

ToTopLink.propTypes = propTypes;

export default ToTopLink;
