import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavItems from './../navItems/NavItems';
import { UnorderedList, ListLinkItem } from './../../base'

function Footer(props) {
    return (
        <footer
            className="navbar navbar-dark bg-dark fixed-bottom">
            <div className="container">
                <div className="col-4">
                    &copy; Latisha McNeel 2019
                </div>
                <div className="col">
                    <div className="d-flex justify-content-between">
                        <NavItems classes={['nav']}/>
                        <UnorderedList classes={['nav']}>
                            <ListLinkItem
                                linkTo="https://www.linkedin/in/latisha-mcneel"
                                classes={['nav-item']}
                                linkClasses={['nav-link']}>
                                <FontAwesomeIcon icon={['fab','linkedin']} />
                            </ListLinkItem>
                            <ListLinkItem
                                linkTo="https://www.github.com/lmcneel"
                                classes={['nav-item']}
                                linkClasses={['nav-link']}>
                                <FontAwesomeIcon icon={['fab','github']} />
                            </ListLinkItem>
                            <ListLinkItem
                                linkTo="https://www.twitter.com/latishamcneel"
                                classes={['nav-item']}
                                linkClasses={['nav-link']}>
                                <FontAwesomeIcon icon={['fab','twitter']} />
                            </ListLinkItem>
                            <ListLinkItem
                                linkTo="https://www.instagram.com/lamcneel/"
                                classes={['nav-item']}
                                linkClasses={['nav-link']}>
                                <FontAwesomeIcon icon={['fab','instagram']} />
                            </ListLinkItem>
                        </UnorderedList>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
