import React from 'react';

function Origin(props) {
    return (
        <div className="card mt-3 bg-dark">
            <div className="card-header">
                <h4 className="card-title">Origin: Texas, United States</h4>
            </div>
            <div className="card-body">
                <ul className="list-unstyled">
                    <li>Latisha McNeel is and has been a Texan her entire life.</li>
                    <li>Currently she resides in the cultural hub of Houston, TX.</li>
                    <li>Latisha is from both country and city, farms and skyscapers.</li>
                </ul>
            </div>
        </div>
    );
}

export default Origin;
