import React from 'react';

function Examples(props) {
    return (
        <div className="card mt-3 bg-dark">
            <div className="card-header">
                <h4 className="card-title">Examples</h4>
            </div>
            <div className="card-body">
                <ul className="list-unstyled">
                    <li>Latisha McNeel is a web artist who enjoys hiking.</li>
                    <li>Latisha McNeel is a woman who programs.</li>
                    <li>Latisha McNeel is a writer and hobbyist photographer.</li>
                </ul>
            </div>
        </div>
    );
}

export default Examples;
